@import url("https://fonts.googleapis.com/css2?family=Lora:ital@0;1&display=swap");
@import url("./font-awesome-4.7.0/scss/font-awesome.scss");

$primary: #f58220;

$breadcrumb-divider-color: white;
$breadcrumb-active-color: white;

@import "../../node_modules/bootstrap/scss/bootstrap";

body {
  overflow-x: hidden;
}

.title {
  background-image: url("../images/dark_embroidery.png");
  background-repeat: repeat;
  background-attachment: fixed;
}
footer {
  background-image: url("../images/herald.png");
  background-repeat: repeat;
  background-color: #f6f6f6;

  h4 {
    font-family: inherit;
    margin-bottom: 2rem;
    position: relative;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lora", Cochin, Georgia, Times, "Times New Roman", serif;
}

.pseudo-title {
  display: block;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.85rem;
  color: $text-muted !important;
}

.social-link {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-right: 1rem;
  display: inline-block;
  border: 1px solid;
  border-radius: 100%;
  text-align: center;
}

.social-line {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  .pseudo-title {
    opacity: 1;
    margin-bottom: 0;
  }
}

#logo {
  width: 150px;
  transition: all 100ms ease-in-out;
}

.gradient-text {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(32%, #e85f35),
    color-stop(100%, #dd9933)
  );
  background: -webkit-linear-gradient(left, #e85f35 32%, #dd9933 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

a.nav-link {
  text-transform: uppercase;
  font-weight: bold;

  &.active {
    @extend .gradient-text;
  }
}

.gallery_thumb {
  background-color: rgb(33, 37, 41);
  a {
    display: grid;
    aspect-ratio: 5/4;
    background-size: 100%;
    background-position: center center;
    text-align: center;
    text-decoration: none;
    color: white;
    transition: all 300ms ease-in-out;

    span {
      display: block;
      background: linear-gradient(0deg, rgba(33, 37, 41), transparent);
      display: block;
      align-self: end;
      padding: 5rem 0 1rem 0;
    }

    &:hover {
      background-size: 110%;
    }

    @media screen and (min-width: 1024px) {
      font-size: 1.25rem;
    }
  }
}

.title-w-divisor {
  margin-bottom: 2.5rem;
  padding-bottom: 2rem;
  position: relative;

  &:before {
    content: "";
    display: block;
    height: 5px;
    width: 100px;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(32%, #e85f35),
      color-stop(100%, #dd9933)
    );
    background: -webkit-linear-gradient(left, #e85f35 32%, #dd9933 100%);
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    left: calc(50% - 50px);
  }
}

img {
  max-width: 100%;
}

.swiper {
  width: 100%;
  background-color: black;
  height: 85vh;

  .photo {
    height: 85vh;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-origin: border-box;
    position: absolute;
    width: 100%;
    opacity: 0.2;
    filter: blur(10px);
  }

  img {
    object-fit: contain;
    margin: 0 auto;
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
  }

  @media screen and (max-width: 1024px) {
    height: fit-content;
    .photo {
      height: fit-content;
    }
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: white !important;
}

.header-pill {
  display: inline-block;
  margin: 0 0.5rem;

  &:last-child {
    margin-right: 0;
  }
}

.cta {
  padding: 8rem 0 5rem 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(33, 37, 41, 0.7);
  }
}

.wrapper {
  align-items: center;
  display: flex;
  height: 90%;
  justify-content: center;
  margin: 5%;
  width: 90%;
}
.aspect {
  padding-bottom: 70%;
  position: relative;
  width: 100%;
}
.aspect-inner {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.flipbook {
  height: 100%;
  transition: margin-left 0.25s ease-out;
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.flipbook .page {
  height: 100%;
  width: 100%;
}
.flipbook .page img {
  max-width: 100%;
  height: 100%;
}

#imageSize {
  opacity: 0;
  position: absolute;
  left: -999em;
}

#macy-loader-container {
  opacity: 0;
  overflow: hidden;
}

.big-thumb {
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(33, 37, 41, 0.7);
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }

  &:hover {
    .overlay {
      opacity: 1;
    }
  }
}

.font-oxygen {
  font-family: 'Montserrat', sans-serif;

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
  }
}

.curso-hero {
  background-image: url('../images/curso/header.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  color: white;
  height: 75vh;
}

.section {
  padding: 3rem 0;

  &.gray {
    color: white;
    background: linear-gradient(90deg, #54595f 0%, #262930 100%);
  }
  &.orange {
    color: white;
    background: linear-gradient(90deg, #ff8300 0%, #f7ce00 100%);
  }
}

.divisor {
  height: 5px;
  width: 200px;
  background-color: #ff8300;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  &.center {
    margin-left: auto;
    margin-right: auto;
  }
}

.container-curso {
  max-width: 100%;
  margin: 0 auto;

  .row-curso {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .col {
    width: 100%;
    flex: 0 0 auto;
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    max-width: 1024px;

    .col {
      width: 50%;
    }
  }
}

.horario {
  border: 2px solid $primary;
  padding: 3rem;
  text-align: center;
  border-radius: $border-radius;

  p:last-child {
    margin-bottom: 0;
  }
}

.photo-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;

  .grid {
    // aspect-ratio: 5/4;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0px 10px 10px rgba(0,0,0,0.1);

    a {
      display: block;
      height: 100%;
    }
  }
}

.giga_text {
  font-family: $font-family-sans-serif;
  font-weight: bolder;
  font-size: 10rem;
}

.sans-serif {
  font-family: $font-family-sans-serif;
}